/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service Admin API
 * Pinwheel Main Service Admin API
 *
 * The version of the OpenAPI document: 2.62.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Device Platform Field
 * @export
 * @enum {string}
 */

export enum DevicePlatform {
    Phone = 'Phone',
    Watch = 'Watch',
    PhoneWatch = 'Phone_Watch'
}


